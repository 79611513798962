import { Slot } from "@radix-ui/react-slot";
import { Stack, type StackType } from "@capterra/vendor-ui-components-layout";
import { Text, type TextType } from "@capterra/vendor-ui-components-text";
import { Link } from "react-router-dom";
import { Link as LinkRR6 } from "react-router-6";
import { Link as LinkRemix } from "@remix-run/react";
import { Button } from "@capterra/vendor-ui-components-button";
import { css, cva, cx } from "ui/css";
import { type ComponentProps, forwardRef } from "react";

export const pageWrapperCSS = css({
  flexGrow: 1,
  paddingInline: "var(--pageSidePadding)",
  paddingBlockStart: "40px",
  paddingBlockEnd: "80px",
});
export const PageWrapper = (props: StackType) => (
  <Stack className={pageWrapperCSS} {...props} />
);

export const Title = ({ children, ...restProps }: ComponentProps<"h1">) => (
  <Text size="28px" weight="bold" color="brand" asChild>
    <h1 {...restProps}>{children}</h1>
  </Text>
);

export const Heading = ({ children, ...restProps }: ComponentProps<"h2">) => (
  <Text size="24px" weight="semibold" color="brand" asChild>
    <h2 {...restProps}>{children}</h2>
  </Text>
);

export const SubHeading = ({
  children,
  color = "brand",
  ...restProps
}: { color?: TextType["color"] } & ComponentProps<"h3">) => (
  <Text size="20px" weight="medium" color={color} asChild>
    <h3 {...restProps}>{children}</h3>
  </Text>
);

export const Para = ({ children, ...restProps }: ComponentProps<"p">) => (
  <Text size="16px" asChild>
    <p {...restProps}>{children}</p>
  </Text>
);

const ListRoot = ({ className, ...props }: ComponentProps<"ul">) => (
  <ul
    className={cx(
      css({
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        listStyle: "none",
      }),
      className,
    )}
    {...props}
  />
);

export const OrderedList = ({ className, ...props }: ComponentProps<"ol">) => (
  <ol
    className={cx(
      css({
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        paddingInlineStart: "24px",

        "& li": {
          listStyleType: "decimal",
        },
      }),
      className,
    )}
    {...props}
  />
);

const listCSS = css({
  textSize: "16px",
  paddingInlineStart: "16px",
  position: "relative",
  display: "list-item !",

  "& .bullet": {
    content: '" "',
    position: "absolute",
    top: "3px",
    left: 0,
    width: "6px",
    height: "6px",
    background: "brandBlue",
  },

  "& a": {
    color: "actionBlue",
    textDecoration: "underline",
  },
});

export const ListItem = ({
  children,
  className,
  ...props
}: ComponentProps<"li">) => (
  <li className={cx(listCSS, className)} {...props}>
    {children}
  </li>
);

const Item = ({ children, ...props }: ComponentProps<typeof ListItem>) => (
  <ListItem {...props}>
    <div className="bullet" />
    {children}
  </ListItem>
);

export const List = Object.assign(ListRoot, { Item });

export const Label = ({
  children,
  weight = "regular",
  ...restProps
}: ComponentProps<"label"> & Pick<TextType, "weight">) => (
  <Text size="14px" color="brand" weight={weight} asChild>
    <label {...restProps}>{children}</label>
  </Text>
);

export const anchorCSS = cva({
  base: {
    color: "actionBlue",
    textDecoration: "underline",
    cursor: "pointer",
    fontSize: "14px",
    border: "none",
    outline: "none",
    background: "transparent",

    _disabled: {
      color: "steel",
    },
  },

  variants: {
    variant: {
      inverted: {
        color: "white",
      },
      dark: {
        color: "black",
      },
    },
  },
});

type AnchorProps = ComponentProps<"a"> & {
  asChild?: boolean;
  variant?: "inverted" | undefined;
};

export const Anchor = forwardRef<HTMLAnchorElement, AnchorProps>(
  ({ asChild, className, variant = undefined, ...props }: AnchorProps, ref) => {
    const Comp = asChild ? Slot : "a";

    return (
      <Comp
        className={cx(anchorCSS({ variant }), className)}
        {...props}
        ref={ref}
      />
    );
  },
);

export const ButtonLink = ({
  className,
  ...props
}: ComponentProps<typeof Button>) => (
  <Button
    className={cx(
      css({
        position: "relative",

        "& a": {
          position: "absolute",
          inset: 0,
          lineHeight: "32px",
        },
      }),
      className,
    )}
    {...props}
  />
);

export const GdmLink = ({
  className,
  ...props
}: ComponentProps<typeof Link>) => (
  <Link
    className={cx(
      css({ color: "actionBlue", textDecoration: "underline" }),
      className,
    )}
    {...props}
  />
);

export const GdmLinkRR6 = ({
  className,
  ...props
}: ComponentProps<typeof LinkRR6>) => (
  <LinkRR6
    className={cx(
      css({ color: "actionBlue", textDecoration: "underline" }),
      className,
    )}
    {...props}
  />
);

export const GdmLinkRemix = ({
  className,
  ...props
}: ComponentProps<typeof LinkRemix>) => (
  <LinkRemix
    className={cx(
      css({ color: "actionBlue", textDecoration: "underline" }),
      className,
    )}
    {...props}
  />
);

export const StatValue = ({
  className,
  ...props
}: ComponentProps<typeof Text>) => (
  <Text
    className={cx(
      css({
        color: "surf",
        fontSize: "36px !",
        fontVariant: "tabular-nums",
      }),
      className,
    )}
    {...props}
  />
);

export const StatAmount = ({
  className,
  ...props
}: ComponentProps<typeof Text>) => (
  <Text
    className={cx(
      css({
        color: "surf",
        fontSize: "24px !",
        fontVariant: "tabular-nums",
        lineHeight: "1.3",
      }),
      className,
    )}
    {...props}
  />
);

export const StatRange = ({
  className,
  ...props
}: ComponentProps<typeof Text>) => (
  <Text
    className={cx(
      css({
        color: "surf",
        fontSize: "24px !",
        fontVariant: "tabular-nums",
      }),
      className,
    )}
    {...props}
  />
);

export const LabelTextSteel = ({
  className,
  ...props
}: ComponentProps<typeof Text>) => (
  <Text
    size="14px"
    color="secondary"
    className={cx(
      css({
        paddingTop: "8px",
      }),
      className,
    )}
    {...props}
  />
);

export const StatValueLarge = ({
  className,
  ...props
}: ComponentProps<typeof Text>) => (
  <Text
    className={cx(
      css({
        fontSize: "40px !",
        fontVariant: "tabular-nums",
        lineHeight: "1.2 !",
      }),
      className,
    )}
    {...props}
  />
);

export const StatValueSmall = ({
  className,
  ...props
}: ComponentProps<typeof Text>) => (
  <Text
    size="20px"
    className={cx(
      css({
        fontVariant: "tabular-nums",
      }),
      className,
    )}
    {...props}
  />
);
